import debounce from "debounce";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./Dashboard.module.scss";

import {
  GetGamesParams,
  UpdateGameConfigParams,
  getGameConfigsOfUser,
  getGames,
  updateGameConfigOfUser,
} from "appdata/games/gamesSlice";
import { AppDispatch, RootState } from "appdata/store";
import CognitiveIcon from "assets/icons/cognitive.svg";
import NeckIcon from "assets/icons/neck.svg";
import NoDataImg from "assets/img/no-data.webp";
import PaginationCus from "components/PaginationCus/PaginationCus";
import { CATEGORIES } from "helps/Categories";
import { UNITS, UNIT_CONFIG } from "helps/UnitConfig";
import { Game, GameConfig, HandShaperConfig } from "types/gameTypes";
import { toastConfig } from "utils/toastConfig";
import { BaseApiParams } from "types/apiTypes";

const categoryIcons: { [key in keyof typeof CATEGORIES]: string | null } = {
  COGNITIVE: CognitiveIcon,
  NECK: NeckIcon,
  ALL: null,
};

interface ConfigCondition {
  key: string;
  min?: number;
  max?: number;
}

interface GameExtend {
  configName: string;
  thumbnail: string;
  fullImage: string;
  conditions?: ConfigCondition[];
}

const gameExtend: GameExtend[] = [
  {
    configName: "puzzles",
    thumbnail: require("assets/img/puzzles_thumb.webp"),
    fullImage: require("assets/img/puzzles.webp"),
    conditions: [
      { key: "timePerTurn", min: 30 },
      { key: "countOfTurn", min: 1 },
    ],
  },
  {
    configName: "tmt_a",
    thumbnail: require("assets/img/tmt_A_thumb.webp"),
    fullImage: require("assets/img/tmt_A.webp"),
    conditions: [
      { key: "maxNumber", min: 2, max: 50 },
      { key: "duration", min: 30 },
    ],
  },
  {
    configName: "tmt_b",
    thumbnail: require("assets/img/tmt_B_thumb.webp"),
    fullImage: require("assets/img/tmt_B.webp"),
    conditions: [
      { key: "maxNumber", min: 2, max: 50 },
      { key: "duration", min: 30 },
    ],
  },
  {
    configName: "neck_throw",
    thumbnail: require("assets/img/headUp&Down_thumb.webp"),
    fullImage: require("assets/img/headUp&Down.webp"),
    conditions: [
      { key: "goalHeadUp", min: 1 },
      { key: "goalHeadDown", min: 1 },
      { key: "durationSecond", min: 30 },
    ],
  },
  {
    configName: "math",
    thumbnail: require("assets/img/add&subtract_thumb.webp"),
    fullImage: require("assets/img/add&subtract.webp"),
    conditions: [{ key: "duration", min: 30 }],
  },
  {
    configName: "line_worker",
    thumbnail: require("assets/img/line_worker_thumb.webp"),
    fullImage: require("assets/img/line_worker.webp"),
    conditions: [
      { key: "goalBend", min: 10 },
      { key: "duration", min: 30 },
    ],
  },
  {
    configName: "hand_shaper",
    thumbnail: require("assets/img/hand_shaper_thumb.webp"),
    fullImage: require("assets/img/hand_shaper.webp"),
    conditions: [
      { key: "totalHand" },
      { key: "gameLevel", min: 1, max: 3 },
      { key: "duration", min: 30 },
    ],
  },
  {
    configName: "memory_card",
    thumbnail: require("assets/img/memory_card_thumb.webp"),
    fullImage: require("assets/img/memory_card.webp"),
    conditions: [{ key: "gameLevel", min: 1, max: 3 }],
  },
  {
    configName: "flip_food",
    thumbnail: require("assets/img/flip_food_thumb.webp"),
    fullImage: require("assets/img/flip_food.webp"),
    conditions: [{ key: "numberOfFlip", min: 10, max: 100 }],
  },
  {
    configName: "jump_frog",
    thumbnail: require("assets/img/flip_food_thumb.webp"),
    fullImage: require("assets/img/flip_food.webp"),
    conditions: [
      { key: "numberOfSquats", min: 5, max: 100 },
      { key: "duration", min: 60 },
    ],
  },
];

const PAGE_SIZE = 8;

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const leftRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);
  const testRef = useRef<HTMLImageElement | null>(null);

  const myselfRedux = useSelector((state: RootState) => state.myselfRedux);
  const gamesRedux = useSelector((state: RootState) => state.gamesRedux);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [selectedCategory, setSelectedCategory] = useState<string>(
    CATEGORIES.ALL
  );
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(
    gamesRedux.games?.[0]
  );
  const [configOfSelectedGame, setConfigOfSelectedGame] =
    useState<GameConfig | null>(null); // Thay đổi kiểu nếu có thông tin cụ thể
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [isConfigGamePopupOpen, setIsConfigGamePopupOpen] =
    useState<boolean>(false);

  const handlePageChange = (paramPaging: BaseApiParams) => {
    dispatch(
      getGames({
        ...paramPaging,
        limit: PAGE_SIZE,
      })
    );
    setCurrentPage(paramPaging.page!);
  };

  const playNow = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length === 0) {
        toast.error(
          "No camera detected! Please connect a camera to play.",
          toastConfig
        );
        return;
      }

      const savedCameraId = localStorage.getItem("defaultCameraId");
      const selectedCameraId =
        savedCameraId &&
        videoDevices.some((device) => device.deviceId === savedCameraId)
          ? savedCameraId
          : videoDevices[0].deviceId;
      console.log(selectedCameraId);

      startGameWithCamera(selectedCameraId);
    });
  };

  const startGameWithCamera = (cameraId: string) => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: cameraId } })
      .then(() => {
        const updateParams: UpdateGameConfigParams = {
          gameId: selectedGame!.id,
          config: {
            config: configOfSelectedGame!,
          },
        };

        dispatch(updateGameConfigOfUser(updateParams))
          .unwrap()
          .then(() => {
            setTimeout(() => {
              let url = `/gameplay?game=${selectedGame!.configName}&userID=${
                myselfRedux.me!.username
              }`;

              if (selectedGame?.configName === "hand_shaper") {
                url += `&totalHand=${
                  (configOfSelectedGame as HandShaperConfig).totalHand
                }`;
              }

              navigate(url);
            }, 200);
          })
          .catch((error) => {
            toast.error("Error updating game configuration!", toastConfig);
          });
      })
      .catch(() => {
        toast.error("Unable to access the selected camera.", toastConfig);
      });
  };

  const handleInputBlur = (
    key: keyof GameConfig,
    min: number | null = null,
    max: number | null = null,
    value: number | string | null = null
  ) => {
    let newValue: any = value;

    if (typeof value === "string") {
      newValue = value.replace(/^0+(?=\d)/, "");
    }

    newValue = Number(value);

    if (min !== null && newValue < min) {
      newValue = min;
    }
    if (max !== null && newValue > max) {
      newValue = max;
    }

    setConfigOfSelectedGame((prevConfig) => ({
      ...prevConfig,
      [key]: newValue,
    }));
  };

  const handleInputChange =
    (key: keyof GameConfig) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = event.target;

      let newValue: any = value;

      const keysToConvert = ["calculationDifficulty", "gameLevel", "totalHand"];

      if (keysToConvert.includes(key)) {
        newValue = Number(value);
      }
      setConfigOfSelectedGame((prevConfig) => ({
        ...prevConfig,
        [key]: key === "isHeadDownMode" ? value === "true" : newValue,
      }));
    };

  const handleGameClick = (game: Game) => {
    setSelectedGame(game);
  };

  useEffect(() => {
    if (!gamesRedux.games || !gamesRedux.gameConfigsOfUser) return;
    setConfigOfSelectedGame(
      gamesRedux.gameConfigsOfUser[`${selectedGame!.configName}_config`]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  useEffect(() => {
    const getGamesDebounce = debounce(() => {
      const params: GetGamesParams = {
        page: 1,
        limit: PAGE_SIZE,
        name: valueSearch,
        type: selectedCategory !== "all" ? [selectedCategory] : undefined,
      };

      dispatch(getGames(params));
    }, 300);

    getGamesDebounce();
    return () => getGamesDebounce.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, valueSearch]);

  useEffect(() => {
    if (!gamesRedux.games) return;

    setSelectedGame(gamesRedux.games[0]);
  }, [gamesRedux]);

  useEffect(() => {
    const params: GetGamesParams = {
      page: 1,
      limit: PAGE_SIZE,
    };

    dispatch(getGames(params));
    dispatch(getGameConfigsOfUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.dashboard_wrapper}>
      {!isConfigGamePopupOpen && (
        <div className={styles.tool_box}>
          <div className={`${styles.category_wrapper}`}>
            {Object.keys(CATEGORIES).map((key) => {
              const categoryValue = CATEGORIES[key as keyof typeof CATEGORIES];
              const Icon =
                categoryIcons[categoryValue as keyof typeof categoryIcons];

              return (
                <span
                  key={key}
                  className={`${
                    selectedCategory === categoryValue
                      ? `text_gradient ${styles.selected_category}`
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedCategory(categoryValue);
                  }}
                >
                  {categoryValue !== "all" && Icon && (
                    <img src={Icon} alt={categoryValue} />
                  )}
                  {t(`game_categories.${categoryValue}`)}
                </span>
              );
            })}
          </div>

          <div
            className={`${styles.search_bar} ${
              isSearchActive ? styles.active : ""
            }`}
            onClick={() => {
              searchRef.current!.focus();
              setIsSearchActive(true);
            }}
          >
            <div className={styles.search_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001a1 1 0 0 0 .152 1.318l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85a1 1 0 0 0-1.318-.152zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
            <input
              ref={searchRef}
              type="text"
              placeholder={t("common_text.search")}
              className={styles.search_input}
              value={valueSearch}
              onChange={(e) => {
                const newValue = e.target.value;
                setValueSearch(newValue);
                setIsSearchActive(newValue.trim() !== "");
              }}
              onFocus={() => setIsSearchActive(true)}
              onBlur={() => {
                if (valueSearch.trim() === "") {
                  setIsSearchActive(false);
                }
              }}
            />
          </div>
        </div>
      )}
      {!isConfigGamePopupOpen && (
        <div className={styles.dashboard_container}>
          {selectedGame && (
            <div className={styles.game_list}>
              {gamesRedux.games &&
                gamesRedux.games.map((game, index) => (
                  <div
                    key={index}
                    className={`${styles.game_item} ${
                      selectedGame?.name === game.name ? styles.selected : ""
                    }`}
                    onClick={() => handleGameClick(game)}
                  >
                    <div className={styles.image_container}>
                      <img
                        src={
                          gameExtend.find(
                            (el) => el.configName === game.configName
                          )!.thumbnail
                        }
                        alt={game.name}
                        className={styles.game_image}
                      />
                      <div className={styles.overlay}>
                        <button
                          className={`${styles.config_button} rounded`}
                          onClick={() => {
                            handleGameClick(game);
                            setIsConfigGamePopupOpen(true);
                          }}
                        >
                          {t("common_text.configPlay")}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`
                    ${selectedGame?.name === game.name ? "text_gradient" : ""}
                    ${styles.game_name}`}
                    >
                      {game.name}
                    </div>
                  </div>
                ))}
            </div>
          )}

          {gamesRedux.metadataGamesPage && (
            <PaginationCus
              metadataPage={gamesRedux.metadataGamesPage}
              onClick={handlePageChange}
              styles={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1vw",
              }}
            />
          )}
          {!selectedGame && (
            <div className={styles.table_nodata}>
              <img src={NoDataImg} alt="nodata"></img>
              <span>{t("common_text.noData")}</span>
            </div>
          )}
        </div>
      )}

      {isConfigGamePopupOpen && selectedGame && configOfSelectedGame && (
        <div className={styles.config_game_container}>
          <div ref={leftRef} className={styles.left}>
            <div className={styles.config_description}>
              <h2 className="text_gradient">{t("common_text.description")}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`game_description.${selectedGame!.configName}`),
                }}
              />
            </div>

            <div className={styles.config_tab}>
              <h2 className="text_gradient">
                {t("common_text.configuration")}
              </h2>

              <div className={styles.config_scroll}>
                {selectedGame!.configName === "neck_throw" &&
                  "durationSecond" in configOfSelectedGame! && (
                    <>
                      <div className={styles.config_item}>
                        <label>{t(`game_config.durationSecond`)}</label>
                        <div className={styles.input_wrapper}>
                          <input
                            type="number"
                            value={configOfSelectedGame?.durationSecond || ""}
                            onChange={handleInputChange(
                              "durationSecond" as keyof GameConfig
                            )}
                            onBlur={() => {
                              handleInputBlur(
                                "durationSecond" as keyof GameConfig,
                                30,
                                null,
                                configOfSelectedGame?.durationSecond || ""
                              );
                            }}
                            onKeyDown={(e) => {
                              if (["-", "e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            className="size_medium"
                          />
                          <span className={styles.unit}>
                            {t("unit.seconds")}
                          </span>
                        </div>
                      </div>
                      <div className={styles.config_item}>
                        <label>{t(`game_config.isHeadDownMode`)}</label>
                        <div className={styles.input_wrapper}>
                          <select
                            value={
                              configOfSelectedGame?.isHeadDownMode
                                ? "true"
                                : "false"
                            } // Chuyển đổi boolean thành chuỗi
                            onChange={handleInputChange(
                              "isHeadDownMode" as keyof GameConfig
                            )}
                            className="size_medium"
                          >
                            <option value={"true"}>
                              {t("game_config.goalHeadDown")}
                            </option>
                            <option value={"false"}>
                              {t("game_config.goalHeadUp")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className={styles.config_item}>
                        <label>{t(`game_config.repetitions`)}</label>
                        <div className={styles.input_wrapper}>
                          <input
                            type="number"
                            value={
                              configOfSelectedGame?.isHeadDownMode
                                ? configOfSelectedGame.goalHeadDown
                                : configOfSelectedGame?.goalHeadUp
                            }
                            onChange={handleInputChange(
                              configOfSelectedGame?.isHeadDownMode
                                ? ("goalHeadDown" as keyof GameConfig)
                                : ("goalHeadUp" as keyof GameConfig)
                            )}
                            onBlur={() => {
                              handleInputBlur(
                                configOfSelectedGame?.isHeadDownMode
                                  ? ("goalHeadDown" as keyof GameConfig)
                                  : ("goalHeadUp" as keyof GameConfig),
                                1,
                                null,
                                configOfSelectedGame?.isHeadDownMode
                                  ? configOfSelectedGame.goalHeadDown
                                  : configOfSelectedGame?.goalHeadUp
                              );
                            }}
                            onKeyDown={(e) => {
                              if (["-", "e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            className="size_medium"
                          />
                          <span className={styles.unit}>{t("unit.count")}</span>
                        </div>
                      </div>
                    </>
                  )}
                {selectedGame?.configName !== "neck_throw" &&
                  Object.entries(configOfSelectedGame).map(([key, value]) => {
                    if (key === "isHeadDownMode") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value}
                              onChange={handleInputChange(
                                key as keyof GameConfig
                              )}
                              className="size_medium"
                            >
                              <option value={"true"}>
                                {t("game_config.goalHeadDown")}
                              </option>
                              <option value={"false"}>
                                {t("game_config.goalHeadUp")}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }
                    if (key === "calculationDifficulty") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value.toString()}
                              onChange={handleInputChange(
                                key as keyof GameConfig
                              )}
                              className="size_medium"
                            >
                              <option value={"1"}>
                                {`${t("game_config.calculationDifficulty")} 1`}
                              </option>
                              <option value={"2"}>
                                {`${t("game_config.calculationDifficulty")} 2`}
                              </option>
                              <option value={"3"}>
                                {`${t("game_config.calculationDifficulty")} 3`}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }

                    if (key === "gameLevel") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value.toString()}
                              onChange={handleInputChange(
                                key as keyof GameConfig
                              )}
                              className="size_medium"
                            >
                              <option value={"1"}>
                                {`${t("game_config.calculationDifficulty")} 1`}
                              </option>
                              <option value={"2"}>
                                {`${t("game_config.calculationDifficulty")} 2`}
                              </option>
                              <option value={"3"}>
                                {`${t("game_config.calculationDifficulty")} 3`}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }

                    if (key === "totalHand") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value.toString()}
                              onChange={handleInputChange(
                                key as keyof GameConfig
                              )}
                              className="size_medium"
                            >
                              <option value={"1"}>
                                {`${t("game_config.oneHand")}`}
                              </option>
                              <option value={"2"}>
                                {`${t("game_config.twoHands")}`}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }

                    if (key !== "timePerTurn") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <input
                              type="number"
                              value={value.toString()}
                              onBlur={() => {
                                const selectedGameConfig = gameExtend.find(
                                  (game) =>
                                    game.configName === selectedGame.configName
                                );
                                const condition =
                                  selectedGameConfig?.conditions?.find(
                                    (cond) => cond.key === key
                                  );

                                handleInputBlur(
                                  key as keyof GameConfig,
                                  condition?.min ?? null,
                                  condition?.max ?? null,
                                  value.toString()
                                );
                              }}
                              onChange={(e) => {
                                handleInputChange(key as keyof GameConfig)(e);
                              }}
                              onKeyDown={(e) => {
                                if (["-", "e", "."].includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              className="size_medium"
                            />
                            <span className={styles.unit}>
                              {UNIT_CONFIG[key] !== UNITS.NONE &&
                                `(${t(`unit.${UNIT_CONFIG[key]}`)})`}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    return null;
                  })}
              </div>
            </div>
          </div>

          <div ref={rightRef} className={styles.right}>
            <div className={styles.overlay}></div>
            <img
              ref={testRef}
              src={
                gameExtend.find(
                  (el) => el.configName === selectedGame.configName
                )!.fullImage
              }
              alt="img"
            />
            <div className={styles.float}>
              <span className={styles.gameName}>{selectedGame.name}</span>
              <button
                className={`${styles.play_button} button_gradient_blue rounded size_large`}
                onClick={playNow}
              >
                {t("common_text.playNow")}
              </button>
            </div>
          </div>
          <button
            className={`${styles.close_btn} size_1 rounded`}
            onClick={() => {
              setIsConfigGamePopupOpen(false);
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
